import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { Paper, Typography, TextField, Button, CircularProgress, Snackbar, Alert } from '@mui/material';

const LoginPage: React.FC = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        const auth = getAuth();
        try {
            await signInWithEmailAndPassword(auth, email, password);
            window.location.href = '/admin';
        } catch (error) {
            console.error("Login error:", error);
            setError('Invalid email or password. Please try again.');
        }
        setLoading(false);
    };

    return (
        <Paper elevation={3} style={{ padding: '20px', maxWidth: '300px', margin: '40px auto' }}>
            <Typography variant="h5" gutterBottom>Admin Login</Typography>
            <form onSubmit={handleLogin}>
                <TextField 
                    fullWidth 
                    margin="normal" 
                    label="Email" 
                    type="email" 
                    value={email} 
                    onChange={(e) => setEmail(e.target.value)} 
                    required 
                />
                <TextField 
                    fullWidth 
                    margin="normal" 
                    label="Password" 
                    type="password" 
                    value={password} 
                    onChange={(e) => setPassword(e.target.value)} 
                    required 
                />
                <Button 
                    type="submit" 
                    variant="contained" 
                    color="primary" 
                    fullWidth 
                    style={{ marginTop: '20px' }}
                    disabled={loading}
                >
                    {loading ? <CircularProgress size={24} /> : 'Login'}
                </Button>
            </form>
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default LoginPage;