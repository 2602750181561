import { useTheme } from "../ThemeContext";
import {
    Box,
    Button,
    Container,
    Divider,
    Grid,
    IconButton,
    Link,
    TextField,
    Typography,
    useMediaQuery,
} from "@mui/material";
import { ArrowRightIcon } from "@mui/x-date-pickers";
import { Facebook, Instagram } from "lucide-react";
import React from "react";

export const Footer = () => {
    const { theme } = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

    return (
        <Box
            component="footer"
            sx={{
                py: 3,
                px: 2,
                mt: "auto",
                backgroundColor: theme.palette.primary.main,
                color: theme.palette.primary.contrastText,
                borderTop: `4px solid ${theme.palette.success.main}`,
            }}
        >
            <Container maxWidth="lg">
                {isMobile ? (
                    <>
                        <Typography variant="body2" align="center" sx={{ mb: 2 }}>
                            Lal Shobuj - Documenting struggles for justice in Bangladesh
                        </Typography>
{/*
                        <Box sx={{ display: "flex", justifyContent: "center", gap: 2, mb: 2 }}>
                            {[
                                { Icon: Facebook, label: "Facebook" },
                                { Icon: Instagram, label: "Instagram" },
                            ].map(({ Icon, label }) => (
                                <IconButton
                                    key={label}
                                    aria-label={label}
                                    sx={{
                                        color: theme.palette.primary.contrastText,
                                        "&:hover": { color: theme.palette.success.main },
                                    }}
                                >
                                    <Icon size={20} />
                                </IconButton>
                            ))}
                        </Box>
*/}
                    </>
                ) : (
                    // Original desktop footer content
                    <Grid container spacing={6}>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                variant="h5"
                                gutterBottom
                                sx={{
                                    fontWeight: "bold",
                                    borderBottom: `2px solid ${theme.palette.success.main}`,
                                    paddingBottom: 1,
                                    marginBottom: 2,
                                }}
                            >
                                Our Mission
                            </Typography>
                            <Typography variant="body2" sx={{ pr: 2 }}>
                                Lal Shobuj documents and supports struggles for justice, equality, and human rights in Bangladesh.
                                We prioritize anonymity and secure data storage.
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                variant="h5"
                                gutterBottom
                                sx={{
                                    fontWeight: "bold",
                                    borderBottom: `2px solid ${theme.palette.success.main}`,
                                    paddingBottom: 1,
                                    marginBottom: 2,
                                }}
                            >
                                Quick Links
                            </Typography>
                            <Typography variant="body2">
                            </Typography>
                        </Grid>
                        <Grid item xs={12} sm={4}>
                            <Typography
                                variant="h5"
                                gutterBottom
                                sx={{
                                    fontWeight: "bold",
                                    borderBottom: `2px solid ${theme.palette.success.main}`,
                                    paddingBottom: 1,
                                    marginBottom: 2,
                                }}
                            >
                                Stay Connected
                            </Typography>
                            <Box
                                sx={{
                                    display: "flex",
                                    justifyContent: isMobile ? "center" : "flex-start",
                                    gap: 2,
                                }}
                            >
                                {[
                                    { Icon: Facebook, label: "Facebook" },
                                    { Icon: Instagram, label: "Instagram" },
                                ].map(({ Icon, label }) => (
                                    <IconButton
                                        key={label}
                                        aria-label={label}
                                        sx={{
                                            color: theme.palette.primary.contrastText,
                                            "&:hover": { color: theme.palette.success.main },
                                        }}
                                    >
                                        <Icon size={24} />
                                    </IconButton>
                                ))}
                            </Box>
                        </Grid>
                    </Grid>
                )}
                <Typography variant="body2" align="center" sx={{ mt: 2, fontSize: isMobile ? '0.7rem' : '0.875rem' }}>
                    &copy; {new Date().getFullYear()} Lal Shobuj. All rights reserved.
                </Typography>
            </Container>
        </Box>
    );
};
