import React, {useState, useEffect} from 'react';
import {collection, query, where, getDocs, doc, updateDoc, Timestamp, addDoc} from 'firebase/firestore';
import {db} from '../../firebaseConfig';
import {Incident} from '../../types/Incident';
import {getFunctions} from 'firebase/functions';
import {getAuth, signInWithEmailAndPassword, signOut} from 'firebase/auth';
import {
    TextField,
    Button,
    Typography,
    Paper,
    Grid,
    CircularProgress,
    Snackbar,
    Card,
    CardContent,
    CardActions,
    Chip,
    Link,
    IconButton,
    Drawer,
    AppBar,
    Toolbar,
    ListItem,
    List, ListItemText, Tabs, Tab
} from '@mui/material';
import {Alert, Box} from '@mui/material';
import {Check, Close, Info, Refresh, ExitToApp, Search, Menu} from '@mui/icons-material';
import {SearchIcon} from "lucide-react";
import RefreshIcon from '@mui/icons-material/Refresh';
import { deleteField } from 'firebase/firestore';
import {incidents} from "../../utils/dataHelper";
import { useNavigate } from 'react-router-dom';


const AdminVerificationPanel: React.FC = () => {
    const [unverifiedIncidents, setUnverifiedIncidents] = useState<Incident[]>([]);
    const [user, setUser] = useState<any>(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedIncident, setSelectedIncident] = useState<Incident | null>(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [searchTerm, setSearchTerm] = useState('');
    const [drawerOpen, setDrawerOpen] = useState(false);
    const functions = getFunctions();
    const auth = getAuth();
    const [isAdmin, setIsAdmin] = useState(false);
    const navigate = useNavigate();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
            if (user) {
                // Check if the user is an admin
                user.getIdTokenResult().then((idTokenResult) => {
                    if (idTokenResult.claims.admin) {
                        setIsAdmin(true);
                        fetchUnverifiedIncidents();
                    } else {
                        setError('You must be an admin to access this page.');
                    }
                });
            } else {
                setIsAdmin(false);
            }
        });
        return () => unsubscribe();
    }, []);

    const fetchUnverifiedIncidents = async () => {
        setLoading(true);
        try {
            const q = query(collection(db, 'incidents'), where('verified', '==', false));
            const querySnapshot = await getDocs(q);
            const incidents = querySnapshot.docs.map(doc => ({id: doc.id, ...doc.data()} as Incident));
            setUnverifiedIncidents(incidents);
        } catch (error) {
            console.error("Error fetching incidents:", error);
            setError('Failed to fetch incidents. Please try again.');
        }
        setLoading(false);
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            console.error("Login error:", error);
            setError('Invalid email or password. Please try again.');
        }
        setLoading(false);
    };

    const handleLogout = () => {
        signOut(auth);
    };

    const handleVerify = async (incident: Incident) => {
        if (!user) {
            setError('User must be authenticated to verify incidents.');
            return;
        }
        setLoading(true);
        try {
            const incidentRef = doc(db, 'incidents', incident.id);
            await updateDoc(incidentRef, {
                verified: true
            });
            setUnverifiedIncidents(prevIncidents => prevIncidents.filter(inc => inc.id !== incident.id));
            setSuccessMessage('Incident verification initiated successfully');
        } catch (error: any) {
            console.error("Error initiating incident verification:", error);
            setError(error.message || 'Failed to initiate incident verification. Please try again.');
        } finally {
            setLoading(false);
            setSelectedIncident(null);
        }
    };
    const handleReject = async (incident: Incident) => {
        // Implement rejection logic here
        console.log("Rejecting incident:", incident.id);
        setSelectedIncident(null);
    };

    const filteredIncidents = unverifiedIncidents.filter(incident =>
        incident.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        incident.description.toLowerCase().includes(searchTerm.toLowerCase())
    );


    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState<'success' | 'error'>('success');
    const [modifyDialogOpen, setModifyDialogOpen] = useState(false);

    const handleRemoveIdField = async () => {
        setLoading(true);
        try {
            const incidentsRef = collection(db, 'incidents');
            const querySnapshot = await getDocs(incidentsRef);

            const updatePromises = querySnapshot.docs.map(docSnapshot => {
                const docRef = doc(db, 'incidents', docSnapshot.id);
                return updateDoc(docRef, {
                    id: deleteField()
                });
            });

            await Promise.all(updatePromises);

            setSnackbarMessage('Successfully removed \'id\' field from all incidents');
            setSnackbarSeverity('success');
            setSnackbarOpen(true);
            console.log("Successfully removed 'id' field from all incidents");

            // Optionally, refresh the incidents list
            fetchUnverifiedIncidents();
        } catch (error) {
            console.error("Error removing 'id' field:", error);
            setSnackbarMessage('Failed to remove \'id\' field. Please try again.');
            setSnackbarSeverity('error');
            setSnackbarOpen(true);
        } finally {
            setLoading(false);
        }
    };

    const handleCloseSnackbar = (event?: React.SyntheticEvent | Event, reason?: string) => {
        if (reason === 'clickaway') {
            return;
        }
        setSnackbarOpen(false);
    };

    if (!user) {
        return (
            <Box sx={{
                height: '100vh',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                background: 'linear-gradient(45deg, #FE6B8B 30%, #FF8E53 90%)'
            }}>
                <Paper elevation={3} sx={{p: 4, width: '300px'}}>
                    <Typography variant="h5" align="center" gutterBottom>Admin Login</Typography>
                    <form onSubmit={handleLogin}>
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Email"
                            value={email}
                            onChange={(e) => setEmail(e.target.value)}
                            required
                        />
                        <TextField
                            fullWidth
                            margin="normal"
                            label="Password"
                            type="password"
                            value={password}
                            onChange={(e) => setPassword(e.target.value)}
                            required
                        />
                        <Button
                            fullWidth
                            variant="contained"
                            color="primary"
                            type="submit"
                            disabled={loading}
                            sx={{mt: 2}}
                        >
                            {loading ? <CircularProgress size={24}/> : 'Login'}
                        </Button>
                    </form>
                    {error && <Alert severity="error" sx={{mt: 2}}>{error}</Alert>}
                </Paper>

                <Snackbar open={snackbarOpen} autoHideDuration={6000} onClose={handleCloseSnackbar}>
                    <Alert onClose={handleCloseSnackbar} severity={error.startsWith('Failed') ? 'error' : 'success'} sx={{ width: '100%' }}>
                        {error}
                    </Alert>
                </Snackbar>
            </Box>
        );
    }

    function importIncidentData() {

        const importIncidentsToFirebase = async () => {
            try {
                const incidentsCollection = collection(db, 'incidents');
                for (const incident of incidents) {
                    await addDoc(incidentsCollection, {
                        ...incident,
                        date: Timestamp.fromDate(new Date(incident.date))
                    });
                }
                console.log('Incidents imported successfully');
            } catch (error) {
                console.error('Error importing incidents: ', error);
            }
        };
        importIncidentsToFirebase();
    }

    return (
        <Box sx={{ display:'flex', overflow: 'auto', p: 2, height: 'calc(100vh - 64px)' }}>
            <AppBar position="fixed" sx={{zIndex: (theme) => theme.zIndex.drawer + 1}}>
                <Toolbar>
                    <IconButton
                        color="inherit"
                        aria-label="open drawer"
                        edge="start"
                        onClick={() => setDrawerOpen(!drawerOpen)}
                        sx={{mr: 2, display: {sm: 'none'}}}
                    >
                        <Menu/>
                    </IconButton>
                    <Typography variant="h6" noWrap component="div" sx={{flexGrow: 1}}>
                        Incident Verification Panel
                    </Typography>

                    <Box>
                        <Button
                            onClick={() => navigate('/admin/modify-incidents')}
                            variant="outlined"
                            color="warning"
                            disabled={loading}
                            sx={{ mr: 2 }}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Modify Incidents'}
                        </Button>
                        <Button
                            onClick={importIncidentData}
                            variant="outlined"
                            color="warning"
                            disabled={loading}
                            sx={{ mr: 2 }}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Import data'}
                        </Button>
                        <Button
                            onClick={handleRemoveIdField}
                            variant="outlined"
                            color="warning"
                            disabled={loading}
                            sx={{ mr: 2 }}
                        >
                            {loading ? <CircularProgress size={24} /> : 'Remove ID Field'}
                        </Button>
                        <Button color="inherit" onClick={handleLogout} startIcon={<ExitToApp/>}>
                            Logout
                        </Button>
                        {/*<ModifyIncidentDialog
                            open={modifyDialogOpen}
                            incident={selectedIncident}
                            onClose={() => setModifyDialogOpen(false)}
                            onSave={() => {
                                setModifyDialogOpen(false);
                                fetchUnverifiedIncidents(); // Refresh the incident list
                            }}
                        />*/}
                    </Box>
                </Toolbar>
            </AppBar>
            <Drawer
                variant="permanent"
                sx={{
                    width: 240,
                    flexShrink: 0,
                    [`& .MuiDrawer-paper`]: { width: 240, boxSizing: 'border-box' },
                }}
            >
                <Toolbar />
                <Box sx={{ overflow: 'auto', p: 2 }}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        placeholder="Search incidents..."
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                        InputProps={{
                            startAdornment: <SearchIcon />,
                        }}
                        sx={{ mb: 2 }}
                    />
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={2}>
                        <Typography variant="subtitle1">Unverified Incidents</Typography>
                        <IconButton onClick={fetchUnverifiedIncidents} color="primary" size="small">
                            <RefreshIcon />
                        </IconButton>
                    </Box>
                    {loading ? (
                        <Box display="flex" justifyContent="center" mt={4}>
                            <CircularProgress />
                        </Box>
                    ) : (
                        <List>
                            {filteredIncidents.map(incident => (
                                <ListItem
                                    key={incident.id}
                                    button
                                    selected={selectedIncident?.id === incident.id}
                                    onClick={() => setSelectedIncident(incident)}
                                >
                                    <ListItemText
                                        primary={incident.title}
                                        secondary={
                                            <React.Fragment>
                                                <Typography variant="body2" color="textSecondary" noWrap>
                                                    {incident.description.substring(0, 50)}...
                                                </Typography>
                                                <Box display="flex" justifyContent="space-between" alignItems="center" mt={1}>
                                                    <Chip
                                                        label={incident.status}
                                                        size="small"
                                                        color="primary"
                                                        sx={{ borderRadius: '4px' }}
                                                    />
                                                    <Typography variant="caption">
                                                        {new Date(incident.date).toLocaleDateString()}
                                                    </Typography>
                                                </Box>
                                            </React.Fragment>
                                        }
                                    />
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Box>
            </Drawer>
            <Box component="main" sx={{flexGrow: 1, p: 3}}>
                <Toolbar/>

                {selectedIncident ? (
                    <Paper elevation={3} sx={{p: 3}}>
                        <Typography variant="h4" gutterBottom>{selectedIncident.title}</Typography>
                        <Chip
                            label={selectedIncident.status}
                            color="primary"
                            sx={{mb: 2, borderRadius: '4px'}}
                        />
                        <Grid container spacing={3}>
                            <Grid item xs={12} md={6}>
                                <Typography variant="h6" gutterBottom>Description</Typography>
                                <Typography paragraph>{selectedIncident.description}</Typography>
                                <Typography variant="h6" gutterBottom>Date</Typography>
                                <Typography paragraph>{new Date(selectedIncident.date).toLocaleString()}</Typography>
                                <Typography variant="h6" gutterBottom>Location</Typography>
                                <Typography paragraph>Lat: {selectedIncident.latitude},
                                    Lng: {selectedIncident.longitude}</Typography>
                                <Typography variant="h6" gutterBottom>Perpetrator</Typography>
                                <Typography paragraph>{selectedIncident.perpetrator || 'Not specified'}</Typography>
                                <Typography variant="h6" gutterBottom>Cause</Typography>
                                <Typography paragraph>{selectedIncident.cause || 'Not specified'}</Typography>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                {selectedIncident.imageUrl && (
                                    <Box mb={2}>
                                        <Typography variant="h6" gutterBottom>Image</Typography>
                                        <img
                                            src={selectedIncident.imageUrl}
                                            alt="Incident"
                                            style={{
                                                width: '100%',
                                                maxHeight: '300px',
                                                objectFit: 'cover',
                                                borderRadius: '8px',
                                                boxShadow: '0 4px 10px rgba(0,0,0,0.1)'
                                            }}
                                        />
                                    </Box>
                                )}
                                {/* Additional content for the right column would go here */}
                            </Grid>
                        </Grid>
                        <Box sx={{ display: 'flex', justifyContent: 'flex-end', mt: 3 }}>
                            <Button
                                variant="contained"
                                color="error"
                                startIcon={<Close />}
                                onClick={() => handleReject(selectedIncident)}
                                sx={{ mr: 2 }}
                            >
                                Reject
                            </Button>
                            <Button
                                variant="contained"
                                color="success"
                                startIcon={<Check />}
                                onClick={() => handleVerify(selectedIncident)}
                            >
                                Verify
                            </Button>
                        </Box>
                    </Paper>
                ) : (
                    <Typography variant="h6" align="center">Select an incident to view details</Typography>
                )}
            </Box>
        </Box>
    );
};
export default AdminVerificationPanel;


/*
import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { Incident } from '../../types/Incident';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { TextField, Button, Typography, Paper, Grid, CircularProgress, Snackbar, Card, CardContent, CardActions, Chip, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { Alert, Box } from '@mui/material';
import { Check, Close, Info } from '@mui/icons-material';

const AdminVerificationPanel: React.FC = () => {
    const [unverifiedIncidents, setUnverifiedIncidents] = useState<Incident[]>([]);
    const [user, setUser] = useState<any>(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [selectedIncident, setSelectedIncident] = useState<Incident | null>(null);
    const functions = getFunctions();
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
            if (user) fetchUnverifiedIncidents();
        });
        return () => unsubscribe();
    }, []);

    const fetchUnverifiedIncidents = async () => {
        setLoading(true);
        try {
            const q = query(collection(db, 'incidents'), where('verified', '==', false));
            const querySnapshot = await getDocs(q);
            const incidents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Incident));
            setUnverifiedIncidents(incidents);
        } catch (error) {
            console.error("Error fetching incidents:", error);
            setError('Failed to fetch incidents. Please try again.');
        }
        setLoading(false);
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            console.error("Login error:", error);
            setError('Invalid email or password. Please try again.');
        }
        setLoading(false);
    };

    const handleLogout = () => {
        signOut(auth);
    };

    const handleVerify = async (incident: Incident) => {
        if (!user) return;
        setLoading(true);
        const verifyIncidentFunction = httpsCallable(functions, 'verifyIncident');
        try {
            await verifyIncidentFunction({ incidentId: incident.id });
            setUnverifiedIncidents(prevIncidents => prevIncidents.filter(inc => inc.id !== incident.id));
        } catch (error) {
            console.error("Error verifying incident:", error);
            setError('Failed to verify incident. Please try again.');
        }
        setLoading(false);
    };

    const handleReject = async (incident: Incident) => {
        // Implement rejection logic here
        console.log("Rejecting incident:", incident.id);
    };

    if (!user) {
        return (
            <Paper elevation={3} style={{ padding: '20px', maxWidth: '300px', margin: '40px auto' }}>
                <Typography variant="h5" gutterBottom>Admin Login</Typography>
                <form onSubmit={handleLogin}>
                    <TextField 
                        fullWidth 
                        margin="normal" 
                        label="Email" 
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                    />
                    <TextField 
                        fullWidth 
                        margin="normal" 
                        label="Password" 
                        type="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        required 
                    />
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary" 
                        fullWidth 
                        style={{ marginTop: '20px' }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Login'}
                    </Button>
                </form>
            </Paper>
        );
    }

    return (
        <Box sx={{ padding: '20px', maxWidth: '1200px', margin: '0 auto' }}>
            <Paper elevation={3} sx={{ padding: '20px', marginBottom: '20px' }}>
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Typography variant="h4" gutterBottom>Unverified Incidents</Typography>
                    <Button onClick={handleLogout} variant="outlined" color="secondary">Logout</Button>
                </Box>
            </Paper>

            {loading ? (
                <Box display="flex" justifyContent="center">
                    <CircularProgress />
                </Box>
            ) : (
                <Grid container spacing={3}>
                    {unverifiedIncidents.map(incident => (
                        <Grid item xs={12} md={6} lg={4} key={incident.id}>
                            <Card elevation={3}>
                                <CardContent>
                                    <Typography variant="h6" gutterBottom>{incident.title}</Typography>
                                    {incident.imageUrl && (
                                        <Box mb={2}>
                                            <img src={incident.imageUrl} alt="Incident" style={{ maxWidth: '100%', height: '200px', objectFit: 'cover' }} />
                                        </Box>
                                    )}
                                    <Typography variant="body2" color="textSecondary" paragraph>
                                        {incident.description.substring(0, 100)}...
                                    </Typography>
                                    <Box display="flex" justifyContent="space-between" alignItems="center">
                                        <Chip label={incident.status} color="primary" size="small" />
                                        <Typography variant="caption">{new Date(incident.date).toLocaleDateString()}</Typography>
                                    </Box>
                                </CardContent>
                                <CardActions>
                                    <Button 
                                        startIcon={<Check />}
                                        onClick={() => handleVerify(incident)} 
                                        variant="contained" 
                                        color="primary" 
                                        size="small"
                                    >
                                        Verify
                                    </Button>
                                    <Button 
                                        startIcon={<Close />}
                                        onClick={() => handleReject(incident)} 
                                        variant="outlined" 
                                        color="secondary" 
                                        size="small"
                                    >
                                        Reject
                                    </Button>
                                    <Button 
                                        startIcon={<Info />}
                                        onClick={() => setSelectedIncident(incident)} 
                                        variant="outlined" 
                                        size="small"
                                    >
                                        Details
                                    </Button>
                                </CardActions>
                            </Card>
                        </Grid>
                    ))}
                </Grid>
            )}

            <Dialog open={!!selectedIncident} onClose={() => setSelectedIncident(null)} maxWidth="md" fullWidth>
                {selectedIncident && (
                    <>
                        <DialogTitle>{selectedIncident.title}</DialogTitle>
                        <DialogContent>
                            <Typography paragraph><strong>Description:</strong> {selectedIncident.description}</Typography>
                            <Typography paragraph><strong>Status:</strong> {selectedIncident.status}</Typography>
                            <Typography paragraph><strong>Date:</strong> {new Date(selectedIncident.date).toLocaleString()}</Typography>
                            <Typography paragraph><strong>Location:</strong> Lat: {selectedIncident.latitude}, Lng: {selectedIncident.longitude}</Typography>
                            <Typography paragraph><strong>Perpetrator:</strong> {selectedIncident.perpetrator}</Typography>
                            <Typography paragraph><strong>Cause:</strong> {selectedIncident.cause}</Typography>
                            {selectedIncident.imageUrl && (
                                <Box mt={2} mb={2}>
                                    <img src={selectedIncident.imageUrl} alt="Incident" style={{ maxWidth: '100%', maxHeight: '300px' }} />
                                </Box>
                            )}
                            {selectedIncident.additionalDetails && (
                                <Typography paragraph><strong>Additional Details:</strong> {selectedIncident.additionalDetails}</Typography>
                            )}
                            {selectedIncident.links && selectedIncident.links.length > 0 && (
                                <Box mt={2}>
                                    <Typography><strong>Related Links:</strong></Typography>
                                    <ul>
                                        {selectedIncident.links.map((link, index) => (
                                            <li key={index}>
                                                <a href={link} target="_blank" rel="noopener noreferrer">{link}</a>
                                            </li>
                                        ))}
                                    </ul>
                                </Box>
                            )}
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={() => setSelectedIncident(null)} color="primary">
                                Close
                            </Button>
                        </DialogActions>
                    </>
                )}
            </Dialog>

            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </Box>
    );
};

export default AdminVerificationPanel;
*/


/*
import React, { useState, useEffect } from 'react';
import { collection, query, where, getDocs } from 'firebase/firestore';
import { db } from '../../firebaseConfig';
import { Incident } from '../../types/Incident';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { getAuth, signInWithEmailAndPassword, signOut } from 'firebase/auth';
import { TextField, Button, Typography, Paper, Grid, CircularProgress, Snackbar } from '@mui/material';
import { Alert } from '@mui/material';

const AdminVerificationPanel: React.FC = () => {
    const [unverifiedIncidents, setUnverifiedIncidents] = useState<Incident[]>([]);
    const [user, setUser] = useState<any>(null);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const functions = getFunctions();
    const auth = getAuth();

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged((user) => {
            setUser(user);
            if (user) fetchUnverifiedIncidents();
        });
        return () => unsubscribe();
    }, []);

    const fetchUnverifiedIncidents = async () => {
        setLoading(true);
        try {
            const q = query(collection(db, 'incidents'), where('verified', '==', false));
            const querySnapshot = await getDocs(q);
            const incidents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Incident));
            setUnverifiedIncidents(incidents);
        } catch (error) {
            console.error("Error fetching incidents:", error);
            setError('Failed to fetch incidents. Please try again.');
        }
        setLoading(false);
    };

    const handleLogin = async (e: React.FormEvent) => {
        e.preventDefault();
        setLoading(true);
        try {
            await signInWithEmailAndPassword(auth, email, password);
        } catch (error) {
            console.error("Login error:", error);
            setError('Invalid email or password. Please try again.');
        }
        setLoading(false);
    };

    const handleLogout = () => {
        signOut(auth);
    };

    const handleVerify = async (incident: Incident) => {
        if (!user) return;
        setLoading(true);
        const verifyIncidentFunction = httpsCallable(functions, 'verifyIncident');
        try {
            await verifyIncidentFunction({ incidentId: incident.id });
            setUnverifiedIncidents(prevIncidents => prevIncidents.filter(inc => inc.id !== incident.id));
        } catch (error) {
            console.error("Error verifying incident:", error);
            setError('Failed to verify incident. Please try again.');
        }
        setLoading(false);
    };

    if (!user) {
        return (
            <Paper elevation={3} style={{ padding: '20px', maxWidth: '300px', margin: '40px auto' }}>
                <Typography variant="h5" gutterBottom>Admin Login</Typography>
                <form onSubmit={handleLogin}>
                    <TextField 
                        fullWidth 
                        margin="normal" 
                        label="Email" 
                        type="email" 
                        value={email} 
                        onChange={(e) => setEmail(e.target.value)} 
                        required 
                    />
                    <TextField 
                        fullWidth 
                        margin="normal" 
                        label="Password" 
                        type="password" 
                        value={password} 
                        onChange={(e) => setPassword(e.target.value)} 
                        required 
                    />
                    <Button 
                        type="submit" 
                        variant="contained" 
                        color="primary" 
                        fullWidth 
                        style={{ marginTop: '20px' }}
                        disabled={loading}
                    >
                        {loading ? <CircularProgress size={24} /> : 'Login'}
                    </Button>
                </form>
            </Paper>
        );
    }

    return (
        <Paper elevation={3} style={{ padding: '20px', margin: '40px auto', maxWidth: '800px' }}>
            <Typography variant="h4" gutterBottom>Unverified Incidents</Typography>
            <Button onClick={handleLogout} variant="outlined" style={{ marginBottom: '20px' }}>Logout</Button>
            {loading ? (
                <CircularProgress />
            ) : (
                <Grid container spacing={3}>
                    {unverifiedIncidents.map(incident => (
                        <Grid item xs={12} key={incident.id}>
                            <Paper elevation={3} style={{ padding: '15px' }}>
                                <Typography variant="h6">{incident.title}</Typography>
                                <Typography variant="body2">{incident.description}</Typography>
                                <Button 
                                    onClick={() => handleVerify(incident)} 
                                    variant="contained" 
                                    color="primary" 
                                    style={{ marginTop: '10px' }}
                                >
                                    Verify
                                </Button>
                            </Paper>
                        </Grid>
                    ))}
                </Grid>
            )}
            <Snackbar open={!!error} autoHideDuration={6000} onClose={() => setError('')}>
                <Alert onClose={() => setError('')} severity="error">
                    {error}
                </Alert>
            </Snackbar>
        </Paper>
    );
};

export default AdminVerificationPanel;


*/


// import React, { useState, useEffect } from 'react';
// import { collection, query, where, getDocs } from 'firebase/firestore';
// import { db } from '../firebaseConfig';
// import { Incident } from '../types/Incident';
// import { useAuth } from '../hooks/useAuth'; // Assume this hook exists for authentication
// import { getFunctions, httpsCallable } from 'firebase/functions';
//
// const AdminVerificationPanel: React.FC = () => {
//     const [unverifiedIncidents, setUnverifiedIncidents] = useState<Incident[]>([]);
//     const { user, isAdmin } = useAuth(); // Custom hook for auth state
//     const functions = getFunctions();
//
//     useEffect(() => {
//         const fetchUnverifiedIncidents = async () => {
//             if (!isAdmin) return;
//             const q = query(collection(db, 'incidents'), where('verified', '==', false));
//             const querySnapshot = await getDocs(q);
//             const incidents = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Incident));
//             setUnverifiedIncidents(incidents);
//         };
//
//         fetchUnverifiedIncidents();
//     }, [isAdmin]);
//
//     const handleVerify = async (incident: Incident) => {
//         if (!isAdmin) return;
//         const verifyIncidentFunction = httpsCallable(functions, 'verifyIncident');
//         try {
//             await verifyIncidentFunction({ incidentId: incident.id });
//             setUnverifiedIncidents(prevIncidents => prevIncidents.filter(inc => inc.id !== incident.id));
//         } catch (error) {
//             console.error("Error verifying incident:", error);
//         }
//     };
//
//     if (!user || !isAdmin) {
//         return <div>You do not have permission to view this page.</div>;
//     }
//
//     return (
//         <div>
//             <h2>Unverified Incidents</h2>
//             {unverifiedIncidents.map(incident => (
//                 <div key={incident.id}>
//                     <h3>{incident.title}</h3>
//                     <p>{incident.description}</p>
//                     <button onClick={() => handleVerify(incident)}>Verify</button>
//                 </div>
//             ))}
//         </div>
//     );
// };
//
// export default AdminVerificationPanel;