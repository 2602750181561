import React, { useState, useEffect, useMemo } from 'react';
import {collection, getDocs, doc, updateDoc, deleteDoc, Timestamp} from 'firebase/firestore';
import {db, firebaseConfig} from '../../firebaseConfig';
import {
    Container, Box, Typography, Table, TableBody, TableCell, TableContainer, TableHead, TableRow,
    Paper, CircularProgress, IconButton, Dialog, DialogTitle, DialogContent, DialogActions,
    TextField, Button, Grid, FormControl, InputLabel, Select, MenuItem, SelectChangeEvent, Chip
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

interface Incident {
    id: string;
    name: string;
    title: string;
    description: string;
    status: string;
    date: string;
    age?: number;
    perpetrator?: string;
    cause?: string;
    education?: string;
    profession?: string;
    verified?: boolean;
    verifiedDate?: string;
    movementType?: string;
    source?: string;
    additionalDetails?: string;
    imageUrl?: string;
}

const HomePage: React.FC = () => {
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [loading, setLoading] = useState(true);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [selectedIncident, setSelectedIncident] = useState<Incident | null>(null);
    const [sortField, setSortField] = useState<'name' | 'status'>('name');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');
    const [searchTerm, setSearchTerm] = useState('');

    const sortedIncidents = useMemo(() => {
        return [...incidents].sort((a, b) => {
            if (sortField === 'name') {
                return sortDirection === 'asc'
                    ? (a.name ?? '').localeCompare(b.name ?? '')
                    : (b.name ?? '').localeCompare(a.name ?? '');
            } else {
                return sortDirection === 'asc'
                    ? (a.status ?? '').localeCompare(b.status ?? '')
                    : (b.status ?? '').localeCompare(a.status ?? '');
            }
        });
    }, [incidents, sortField, sortDirection]);

    const filteredIncidents = useMemo(() => {
        return sortedIncidents.filter(incident =>
            (incident.name?.toLowerCase().includes(searchTerm.toLowerCase()) || false) ||
            (incident.title?.toLowerCase().includes(searchTerm.toLowerCase()) || false)
        );
    }, [sortedIncidents, searchTerm]);
    const handleSort = (field: 'name' | 'status') => {
        if (field === sortField) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    useEffect(() => {
        fetchIncidents();
    }, []);

    const fetchIncidents = async () => {
        setLoading(true);
        const incidentsCollection = collection(db, 'incidents');
        const incidentsSnapshot = await getDocs(incidentsCollection);
        const incidentsList = incidentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Incident));
        setIncidents(incidentsList);
        setLoading(false);
    };

    const handleIncidentSelect = (incident: Incident) => {
        setSelectedIncident(incident);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedIncident(null);
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
    ) => {
        const { name, value } = e.target;
        if (selectedIncident && name) {
            setSelectedIncident({
                ...selectedIncident,
                [name]: value,
            });
        }
    };
/*
    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        const { name, value } = event.target;
        setSelectedIncident(prev => prev ? { ...prev, [name]: value } : null);
    };
*/

    const handleSave = async () => {
        if (selectedIncident && selectedIncident.id) {
            try {
                setLoading(true);
                const incidentRef = doc(db, 'incidents', selectedIncident.id);
                const { id, date, ...updateData } = selectedIncident;

                // Convert date string to Firestore Timestamp in UTC
                const formattedDate = new Date(date);
                if (isNaN(formattedDate.getTime())) {
                    throw new Error("Invalid date value");
                }

                const timestamp = Timestamp.fromDate(formattedDate);

                await updateDoc(incidentRef, { ...updateData, date: timestamp });

                handleDialogClose();
                await fetchIncidents();
            } catch (error) {
                console.error("Error updating incident: ", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleDelete = async (id: string) => {
        if (window.confirm('Are you sure you want to delete this incident?')) {
            try {
                setLoading(true);
                await deleteDoc(doc(db, 'incidents', id));
                await fetchIncidents();
            } catch (error) {
                console.error("Error deleting incident: ", error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Container maxWidth="lg">
            <Box my={4}>
                <Typography variant="h4" gutterBottom>Modify Incidents</Typography>
                <Box display="flex" mb={2}>
                    <TextField
                        fullWidth
                        variant="outlined"
                        label="Search incidents"
                        value={searchTerm}
                        onChange={(e) => setSearchTerm(e.target.value)}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={() => {/* You can add additional search logic here if needed */}}
                        style={{ marginLeft: '10px' }}
                    >
                        Search
                    </Button>
                </Box>
                {loading ? (
                    <Box display="flex" justifyContent="center" my={4}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <TableContainer component={Paper} elevation={3}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell onClick={() => handleSort('name')}>
                                        Name {sortField === 'name' && (sortDirection === 'asc' ? '▲' : '▼')}
                                    </TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell onClick={() => handleSort('status')}>
                                        Status {sortField === 'status' && (sortDirection === 'asc' ? '▲' : '▼')}
                                    </TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {filteredIncidents.map((incident) => (
                                    <TableRow key={incident.id} hover>
                                        <TableCell>{incident.name}</TableCell>
                                        <TableCell>{incident.title}</TableCell>
                                        <TableCell>
                                            <Chip 
                                                label={incident.status} 
                                                color={incident.status === 'killed' ? 'error' : 'primary'}
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell>{new Date(incident.date).toLocaleDateString()}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleIncidentSelect(incident)} color="primary">
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(incident.id)} color="error">
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>

            <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="md">
                <DialogTitle>Modify Incident</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField margin="dense" label="Title" name="title" fullWidth value={selectedIncident?.title || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" label="Description" name="description" fullWidth multiline rows={4} value={selectedIncident?.description || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Name" name="name" fullWidth value={selectedIncident?.name || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Age" name="age" type="number" fullWidth value={selectedIncident?.age || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <InputLabel>Status</InputLabel>
                                <Select name="status" value={selectedIncident?.status || ''} onChange={handleChange}>
                                    <MenuItem value="killed">Killed</MenuItem>
                                    <MenuItem value="injured">Injured</MenuItem>
                                    <MenuItem value="missing">Missing</MenuItem>
                                    <MenuItem value="detained">Detained</MenuItem>
                                    <MenuItem value="harassed">Harassed</MenuItem>
                                    <MenuItem value="threatened">Threatened</MenuItem>
                                    <MenuItem value="recovered">Recovered</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Date" name="date" type="date" fullWidth value={selectedIncident?.date || ''} onChange={handleChange} InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Perpetrator" name="perpetrator" fullWidth value={selectedIncident?.perpetrator || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Cause" name="cause" fullWidth value={selectedIncident?.cause || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Education" name="education" fullWidth value={selectedIncident?.education || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Profession" name="profession" fullWidth value={selectedIncident?.profession || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <InputLabel>Verified</InputLabel>
                                <Select name="verified" value={selectedIncident?.verified?.toString() || 'false'} onChange={handleChange}>
                                    <MenuItem value="true">Yes</MenuItem>
                                    <MenuItem value="false">No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Verified Date" name="verifiedDate" type="date" fullWidth value={selectedIncident?.verifiedDate || ''} onChange={handleChange} InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Movement Type" name="movementType" fullWidth value={selectedIncident?.movementType || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Source" name="source" fullWidth value={selectedIncident?.source || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" label="Additional Details" name="additionalDetails" fullWidth multiline rows={3} value={selectedIncident?.additionalDetails || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" label="Image URL" name="imageUrl" fullWidth value={selectedIncident?.imageUrl || ''} onChange={handleChange} />
                        </Grid>
            </Grid>
        </DialogContent>


                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSave} color="primary">
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default HomePage;

/*
import React, {useState, useEffect, useMemo} from 'react';
import {
    Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper,
    Button, Dialog, DialogActions, DialogContent, DialogTitle, TextField,
    Select, MenuItem, FormControl, InputLabel, CircularProgress, Typography,
    Container, Box, Grid, Chip, IconButton
} from '@mui/material';
import { Edit as EditIcon, Delete as DeleteIcon } from '@mui/icons-material';
import { collection, getDocs, doc, updateDoc, deleteDoc } from 'firebase/firestore';
import {db, firebaseConfig} from '../../firebaseConfig';
import { Incident } from '../../types/Incident';
import { SelectChangeEvent } from "@mui/material/Select";
import ReCAPTCHA from "react-google-recaptcha";

const ModifyIncidentsPage: React.FC = () => {
    const [incidents, setIncidents] = useState<Incident[]>([]);
    const [selectedIncident, setSelectedIncident] = useState<Incident | null>(null);
    const [dialogOpen, setDialogOpen] = useState(false);
    const [loading, setLoading] = useState(false);

    const [sortField, setSortField] = useState<'name' | 'status'>('name');
    const [sortDirection, setSortDirection] = useState<'asc' | 'desc'>('asc');

   const sortedIncidents = useMemo(() => {
    return [...incidents].sort((a, b) => {
        if (sortField === 'name') {
            return sortDirection === 'asc'
                ? (a.name ?? '').localeCompare(b.name ?? '')
                : (b.name ?? '').localeCompare(a.name ?? '');
        } else {
            return sortDirection === 'asc'
                ? (a.status ?? '').localeCompare(b.status ?? '')
                : (b.status ?? '').localeCompare(a.status ?? '');
        }
    });
}, [incidents, sortField, sortDirection]);

    const handleSort = (field: 'name' | 'status') => {
        if (field === sortField) {
            setSortDirection(sortDirection === 'asc' ? 'desc' : 'asc');
        } else {
            setSortField(field);
            setSortDirection('asc');
        }
    };

    useEffect(() => {
        fetchIncidents();
    }, []);

    const fetchIncidents = async () => {
        setLoading(true);
        const incidentsCollection = collection(db, 'incidents');
        const incidentsSnapshot = await getDocs(incidentsCollection);
        const incidentsList = incidentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() } as Incident));
        setIncidents(incidentsList);
        setLoading(false);
    };

    const handleIncidentSelect = (incident: Incident) => {
        setSelectedIncident(incident);
        setDialogOpen(true);
    };

    const handleDialogClose = () => {
        setDialogOpen(false);
        setSelectedIncident(null);
    };

    const handleChange = (
        e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<string>
    ) => {
        const { name, value } = e.target;
        if (selectedIncident && name) {
            setSelectedIncident({
                ...selectedIncident,
                [name]: value,
            });
        }
    };

    const handleSave = async () => {
        if (selectedIncident && selectedIncident.id) {
            try {
                setLoading(true);
                const incidentRef = doc(db, 'incidents', selectedIncident.id);
                const { id, ...updateData } = selectedIncident;
                await updateDoc(incidentRef, updateData);
                handleDialogClose();
                await fetchIncidents();
            } catch (error) {
                console.error("Error updating incident: ", error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleDelete = async (id: string) => {
        if (window.confirm('Are you sure you want to delete this incident?')) {
            try {
                setLoading(true);
                await deleteDoc(doc(db, 'incidents', id));
                await fetchIncidents();
            } catch (error) {
                console.error("Error deleting incident: ", error);
            } finally {
                setLoading(false);
            }
        }
    };

    return (
        <Container maxWidth="lg">
            <Box my={4}>
                <Typography variant="h4" gutterBottom>Modify Incidents</Typography>
                {loading ? (
                    <Box display="flex" justifyContent="center" my={4}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <TableContainer component={Paper} elevation={3}>
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell onClick={() => handleSort('name')}>
                                        Name {sortField === 'name' && (sortDirection === 'asc' ? '▲' : '▼')}
                                    </TableCell>
                                    <TableCell>Title</TableCell>
                                    <TableCell>Status</TableCell>
                                    <TableCell>Date</TableCell>
                                    <TableCell>Actions</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {incidents.map((incident) => (
                                    <TableRow key={incident.id} hover>
                                        <TableCell>{incident.name}</TableCell>
                                        <TableCell>{incident.title}</TableCell>
                                        <TableCell>
                                            <Chip 
                                                label={incident.status} 
                                                color={incident.status === 'killed' ? 'error' : 'primary'}
                                                size="small"
                                            />
                                        </TableCell>
                                        <TableCell>{new Date(incident.date).toLocaleDateString()}</TableCell>
                                        <TableCell>
                                            <IconButton onClick={() => handleIncidentSelect(incident)} color="primary">
                                                <EditIcon />
                                            </IconButton>
                                            <IconButton onClick={() => handleDelete(incident.id)} color="error">
                                                <DeleteIcon />
                                            </IconButton>
                                        </TableCell>
                                    </TableRow>
                                ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </Box>

            <Dialog open={dialogOpen} onClose={handleDialogClose} fullWidth maxWidth="md">
                <DialogTitle>Modify Incident</DialogTitle>
                <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField margin="dense" label="Title" name="title" fullWidth value={selectedIncident?.title || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" label="Description" name="description" fullWidth multiline rows={4} value={selectedIncident?.description || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Name" name="name" fullWidth value={selectedIncident?.name || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Age" name="age" type="number" fullWidth value={selectedIncident?.age || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <InputLabel>Status</InputLabel>
                                <Select name="status" value={selectedIncident?.status || ''} onChange={handleChange}>
                                    <MenuItem value="killed">Killed</MenuItem>
                                    <MenuItem value="injured">Injured</MenuItem>
                                    <MenuItem value="missing">Missing</MenuItem>
                                    <MenuItem value="detained">Detained</MenuItem>
                                    <MenuItem value="harassed">Harassed</MenuItem>
                                    <MenuItem value="threatened">Threatened</MenuItem>
                                    <MenuItem value="recovered">Recovered</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Date" name="date" type="date" fullWidth value={selectedIncident?.date || ''} onChange={handleChange} InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Perpetrator" name="perpetrator" fullWidth value={selectedIncident?.perpetrator || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Cause" name="cause" fullWidth value={selectedIncident?.cause || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Education" name="education" fullWidth value={selectedIncident?.education || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Profession" name="profession" fullWidth value={selectedIncident?.profession || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <InputLabel>Verified</InputLabel>
                                <Select name="verified" value={selectedIncident?.verified?.toString() || 'false'} onChange={handleChange}>
                                    <MenuItem value="true">Yes</MenuItem>
                                    <MenuItem value="false">No</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Verified Date" name="verifiedDate" type="date" fullWidth value={selectedIncident?.verifiedDate || ''} onChange={handleChange} InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Movement Type" name="movementType" fullWidth value={selectedIncident?.movementType || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Source" name="source" fullWidth value={selectedIncident?.source || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" label="Additional Details" name="additionalDetails" fullWidth multiline rows={3} value={selectedIncident?.additionalDetails || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" label="Image URL" name="imageUrl" fullWidth value={selectedIncident?.imageUrl || ''} onChange={handleChange} />
                        </Grid>
                        {/!*<Grid item xs={12}>
                            <Box mt={2}>
                                <ReCAPTCHA
                                    sitekey={firebaseConfig.recaptchaSiteKey || ''}
                                    onChange={(value) => setRecaptchaValue(value)}
                                />
                            </Box>                        </Grid>*!/}

                    </Grid>
                </DialogContent>
              {/!*  <DialogContent>
                    <Grid container spacing={2}>
                        <Grid item xs={12}>
                            <TextField margin="dense" label="Title" name="title" fullWidth value={selectedIncident?.title || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" label="Description" name="description" fullWidth multiline rows={4} value={selectedIncident?.description || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <FormControl fullWidth margin="dense">
                                <InputLabel>Status</InputLabel>
                                <Select name="status" value={selectedIncident?.status || ''} onChange={handleChange}>
                                    <MenuItem value="killed">Killed</MenuItem>
                                    <MenuItem value="injured">Injured</MenuItem>
                                    <MenuItem value="missing">Missing</MenuItem>
                                    <MenuItem value="detained">Detained</MenuItem>
                                    <MenuItem value="harassed">Harassed</MenuItem>
                                    <MenuItem value="threatened">Threatened</MenuItem>
                                    <MenuItem value="recovered">Recovered</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Date" name="date" type="date" fullWidth value={selectedIncident?.date || ''} onChange={handleChange} InputLabelProps={{ shrink: true }} />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" label="Location" name="location" fullWidth value={selectedIncident?.location || ''} onChange={handleChange} />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Latitude" name="latitude" fullWidth value={selectedIncident?.latitude || ''} onChange={handleChange} type="number" />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField margin="dense" label="Longitude" name="longitude" fullWidth value={selectedIncident?.longitude || ''} onChange={handleChange} type="number" />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField margin="dense" label="Source" name="source" fullWidth value={selectedIncident?.source || ''} onChange={handleChange} />
                        </Grid>
                    </Grid>
                </DialogContent>*!/}
                <DialogActions>
                    <Button onClick={handleDialogClose} color="primary">Cancel</Button>
                    <Button onClick={handleSave} color="primary" variant="contained">Save</Button>
                </DialogActions>
            </Dialog>
        </Container>
    );
};

export default ModifyIncidentsPage;
*/
