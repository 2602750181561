import React, { Component, ErrorInfo, ReactNode } from 'react';
import ReactGA from 'react-ga4';  // Add this import

interface Props {
  children: ReactNode;
  fallback: ReactNode;
}

interface State {
  hasError: boolean;
}

class ErrorBoundary extends Component<Props, State> {
  public state: State = {
    hasError: false
  };

  public static getDerivedStateFromError(_: Error): State {
    return { hasError: true };
  }

  public componentDidCatch(error: Error, errorInfo: ErrorInfo) {
    console.error('Uncaught error:', error, errorInfo);
    // Log the error to Google Analytics
    ReactGA.event('exception', {
      description: `${error.toString()} | ${JSON.stringify(errorInfo)}`,
      fatal: true
    });
  }

  public render() {
    if (this.state.hasError) {
      return this.props.fallback;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;