import React, {Suspense, lazy, ReactNode, useEffect} from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import { QueryClient, QueryClientProvider } from 'react-query';
import ThemeProvider from './ThemeContext';
import { motion, AnimatePresence } from 'framer-motion';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import { Link as RouterLink } from 'react-router-dom';
import ReactGA from "react-ga4";

import {
    Box,
    CssBaseline,
    CircularProgress,
    Breadcrumbs,
    Link,
    Typography,
    useScrollTrigger,
    Zoom,
    Fab,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import Header from './components/Header';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { Footer } from './components/Footer';
import ProtectedRoute from './components/ProtectedRoute';
import AdminVerificationPanel from './components/admin/AdminVerificationPanel';
import LoginPage from './components/LoginPage';
import ErrorBoundary from './components/ErrorBoundary';
import ModifyIncidentPage from "./components/admin/ModifyIncidentPage";

// Lazy load components
const HomePage = lazy(() => import('./components/home/HomePage'));
const Memorial = lazy(() => import('./components/MemorialSection'));

const pageVariants = {
    initial: { opacity: 0, y: 20 },
    in: { opacity: 1, y: 0 },
    out: { opacity: 0, y: -20 },
};

const pageTransition = {
    type: 'tween',
    ease: 'anticipate',
    duration: 0.3,
};

const queryClient = new QueryClient();

const SkeletonLoader = () => (
    <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
        <CircularProgress />
    </Box>
);

const BreadcrumbNavigation = () => {
    const location = useLocation();
    const pathnames = location.pathname.split('/').filter((x) => x);
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    if (pathnames.length === 0) return null;

    return (
        <Box sx={{ width: '100%', overflowX: 'auto', bgcolor: 'background.paper', p: isMobile ? 1 : 2 }}>
            <Breadcrumbs
                aria-label="breadcrumb"
                sx={{
                    padding: isMobile ? theme.spacing(1, 2) : theme.spacing(2),
                    whiteSpace: 'nowrap',
                }}
            >
                <Link component={RouterLink} to="/" underline="hover" color="inherit">
                    {t('home')}
                </Link>
                {pathnames.map((value, index) => {
                    const last = index === pathnames.length - 1;
                    const to = `/${pathnames.slice(0, index + 1).join('/')}`;
                    return last ? (
                        <Typography color="textPrimary" key={to}>
                            {t(value)}
                        </Typography>
                    ) : (
                        <Link component={RouterLink} to={to} key={to} underline="hover" color="inherit">
                            {t(value)}
                        </Link>
                    );
                })}
            </Breadcrumbs>
        </Box>
    );
};

const ScrollToTop = () => {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 100,
    });

    const handleClick = () => {
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    return (
        <Zoom in={trigger}>
            <Fab
                color="primary"
                size="small"
                onClick={handleClick}
                sx={{
                    position: 'fixed',
                    bottom: 16,
                    right: 16,
                    bgcolor: 'secondary.main',
                    '&:hover': {
                        bgcolor: 'secondary.dark',
                    },
                }}
            >
                <KeyboardArrowUpIcon />
            </Fab>
        </Zoom>
    );
};

const ContentFadeIn: React.FC<{ children: ReactNode }> = ({ children }) => (
    <motion.div initial="initial" animate="in" exit="out" variants={pageVariants} transition={pageTransition}>
        {children}
    </motion.div>
);

const AnimatedRoutes = () => {
    const location = useLocation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    useEffect(() => {
        // Send pageview on route change
        ReactGA.send({ hitType: "pageview", page: location.pathname + location.search });
    }, [location]);
    return (
        <AnimatePresence mode='wait'>
            <Box
                maxWidth={isMobile ? '100%' : 'lg'}
                sx={{
                    width: '100%',
                    px: isMobile ? 0 : 3,
                    mt: isMobile ? 2 : 4,
                    mb: isMobile ? 2 : 4,
                    mx: 'auto',
                    bgcolor: 'background.default',
                }}
            >
                <Routes location={location} key={location.pathname}>
                    <Route path="/" element={<ContentFadeIn><HomePage /></ContentFadeIn>} />
                    <Route path="/memorial" element={<ContentFadeIn><Memorial /></ContentFadeIn>} />
                    <Route path="/login" element={<ContentFadeIn><LoginPage /></ContentFadeIn>} />
                    <Route path="/admin" element={
                        <ProtectedRoute><ContentFadeIn><AdminVerificationPanel /></ContentFadeIn></ProtectedRoute>
                    } />
                    <Route path="/admin/modify-incidents" element={
                        <ProtectedRoute><ContentFadeIn><ModifyIncidentPage /></ContentFadeIn></ProtectedRoute>
                    } />
                </Routes>
            </Box>
        </AnimatePresence>
    );
};

const App = () => {
    const { t } = useTranslation();
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));

    useEffect(() => {
        // Initialize GA
        ReactGA.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID || '');        // Send initial pageview
        ReactGA.send({ hitType: "pageview", page: window.location.pathname + window.location.search });
    }, []);

    return (
        <QueryClientProvider client={queryClient}>
            <ThemeProvider>
                <Router>
                    <Helmet>
                        <title>Lal Shobuj - Birodh • Obhijog • Mukti • Protirodh</title>
                        <meta name="viewport" content="width=device-width, initial-scale=1, maximum-scale=1" />
                    </Helmet>
                    <CssBaseline />
                    <Box sx={{ display: 'flex', flexDirection: 'column', minHeight: '100vh' }}>
                        <Header />
                        <BreadcrumbNavigation />
                        <Box component="main" sx={{ flexGrow: 1, width: '100%' }}>
                            <ErrorBoundary fallback={<SkeletonLoader />}>
                                <Suspense fallback={<SkeletonLoader />}>
                                    <AnimatedRoutes />
                                </Suspense>
                            </ErrorBoundary>
                        </Box>
                        <ScrollToTop />
                        <Footer />
                    </Box>
                </Router>
            </ThemeProvider>
        </QueryClientProvider>
    );
};

export default App;
