import React, { useState, useCallback } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    IconButton,
    useTheme,
    useMediaQuery,
    Tooltip,
    Button,
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import MapIcon from '@mui/icons-material/Map';
import NewsIcon from '@mui/icons-material/Announcement';
import MemorialIcon from '@mui/icons-material/EmojiPeople';
import ThemeSwitcher from '../ThemeSwitcher';

const menuItems = [
    { text: 'Home', icon: <MapIcon />, path: '/' },
    // { text: 'Video Feed', icon: <NewsIcon />, path: '/videos' },
    { text: 'Memorial', icon: <MemorialIcon />, path: '/memorial' },
];

const Header: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();

    const toggleDrawer = useCallback((open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    }, []);

    const DrawerContent = () => (
        <Box
            sx={{
                width: 250,
                backgroundColor: 'background.paper',
                height: '100%',
                py: 2,
                px: 3,
            }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {menuItems.map((item) => (
                    <ListItem
                        button
                        key={item.text}
                        component={Link}
                        to={item.path}
                        selected={location.pathname === item.path}
                        sx={{
                            backgroundColor: location.pathname === item.path ? 'action.selected' : 'inherit',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <AppBar
            position="sticky"
            sx={{
                backgroundColor: 'primary.dark',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            }}
        >
            <Toolbar
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center',
                    py: 2,
                    px: 3,
                }}
            >
                {isMobile && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <Typography
                        variant="h5"
                        component={Link}
                        to="/"
                        sx={{
                            fontWeight: 'bold',
                            letterSpacing: '0.5px',
                            marginRight: 2,
                            textDecoration: 'none',
                            '&:hover': {
                                opacity: 0.8,
                            },
                        }}
                    >
                        <span style={{ color: '#FF0000' }}>Lal</span>{' '}
                        <span style={{ color: '#00FF00' }}>Shobuj</span>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            color: 'common.white',
                            opacity: 0.9,
                            display: { xs: 'none', sm: 'inline' },
                            fontStyle: 'italic',
                            textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                        }}
                    >
                        Birodh • Obhijog • Mukti • Protirodh
                    </Typography>
                </Box>
                {!isMobile ? (
                    <nav>
                        <Box sx={{ display: 'flex' }}>
                            {menuItems.map((item) => (
                                <Tooltip title={item.text} key={item.text}>
                                    <Button
                                        component={Link}
                                        to={item.path}
                                        sx={{
                                            color: 'common.white',
                                            textTransform: 'none',
                                            mx: 1,
                                            fontWeight: location.pathname === item.path ? 'bold' : 'normal',
                                                borderBottom: location.pathname === item.path ? '2px solid white' : 'none',
                                            borderRadius: '4px 4px 0 0',
                                            '&:hover': {
                                            backgroundColor: 'rgba(255,255,255,0.1)',
                                        },
                                        }}
                                    >
                                        {item.text}
                                    </Button>
                                </Tooltip>
                            ))}
                        </Box>
                    </nav>
                ) : (
                    <Typography variant="subtitle1" sx={{ color: 'common.white' }}>
                        {menuItems.find((item) => item.path === location.pathname)?.text || 'Home'}
                    </Typography>
                )}
                <ThemeSwitcher />
            </Toolbar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <DrawerContent />
            </Drawer>
        </AppBar>
    );
};

export default React.memo(Header);



/*import React, { useState, useCallback } from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    IconButton,
    useTheme,
    useMediaQuery,
    Tooltip,
    Button
} from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import MapIcon from '@mui/icons-material/Map';
import NewsIcon from '@mui/icons-material/Announcement';
import MemorialIcon from '@mui/icons-material/EmojiPeople';
import ThemeSwitcher from '../ThemeSwitcher';

const menuItems = [
    { text: 'Home', icon: <MapIcon />, path: '/' },
/!*
    { text: 'Video Feed', icon: <NewsIcon />, path: '/videos' },
*!/
    { text: 'Memorial', icon: <MemorialIcon />, path: '/memorial' },
];

const Header: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();

    const toggleDrawer = useCallback((open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    }, []);

    const DrawerContent = () => (
        <Box
            sx={{ width: 250, backgroundColor: 'background.paper', height: '100%' }}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {menuItems.map((item) => (
                    <ListItem
                        button
                        key={item.text}
                        component={Link}
                        to={item.path}
                        selected={location.pathname === item.path}
                        sx={{
                            backgroundColor: location.pathname === item.path ? 'action.selected' : 'inherit',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <AppBar
            position="sticky"
            sx={{
                backgroundColor: 'primary.dark',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            }}
        >
            <Toolbar>
                {isMobile && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <Box sx={{ flexGrow: 1, display: 'flex', alignItems: 'center' }}>
                    <Typography
                        variant="h5"
                        component={Link}
                        to="/"
                        sx={{
                            fontWeight: 'bold',
                            letterSpacing: '0.5px',
                            marginRight: 2,
                            textDecoration: 'none',
                            '&:hover': {
                                opacity: 0.8,
                            },
                        }}
                    >
                        <span style={{ color: '#FF0000' }}>Lal</span>{' '}
                        <span style={{ color: '#00FF00' }}>Shobuj</span>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            color: 'common.white',
                            opacity: 0.9,
                            display: { xs: 'none', sm: 'inline' },
                            fontStyle: 'italic',
                            textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                        }}
                    >
                        Birodh • Obhijog • Mukti • Protirodh
                    </Typography>
                </Box>
                {!isMobile ? (
                    <nav>
                        <Box sx={{ display: 'flex' }}>
                            {menuItems.map((item) => (
                                <Tooltip title={item.text} key={item.text}>
                                    <Button
                                        component={Link}
                                        to={item.path}
                                        sx={{
                                            color: 'common.white',
                                            textTransform: 'none',
                                            mx: 1,
                                            fontWeight: location.pathname === item.path ? 'bold' : 'normal',
                                            borderBottom: location.pathname === item.path ? '2px solid white' : 'none',
                                            borderRadius: '4px 4px 0 0',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255,255,255,0.1)',
                                            },
                                        }}
                                    >
                                        {item.text}
                                    </Button>
                                </Tooltip>
                            ))}
                        </Box>
                    </nav>
                ) : (
                    <Typography variant="subtitle1" sx={{ color: 'common.white' }}>
                        {menuItems.find(item => item.path === location.pathname)?.text || 'Home'}
                    </Typography>
                )}
                <ThemeSwitcher />
            </Toolbar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <DrawerContent />
            </Drawer>
        </AppBar>
    );
};

export default React.memo(Header);*/

/*
import React, {useState, useCallback} from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    IconButton,
    useTheme,
    useMediaQuery,
    Tooltip,
    Button
} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import MapIcon from '@mui/icons-material/Map';
import NewsIcon from '@mui/icons-material/Announcement';
import MemorialIcon from '@mui/icons-material/EmojiPeople';
import ThemeSwitcher from '../ThemeSwitcher';
import FlagIcon from '@mui/icons-material/Flag';

const menuItems = [
    {text: 'Home', icon: <MapIcon/>, path: '/'},
    {text: 'News Feed', icon: <NewsIcon/>, path: '/news'},
    {text: 'Memorial', icon: <MemorialIcon/>, path: '/memorial'},
];

const Header: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();

    const toggleDrawer = useCallback((open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    }, []);

    const DrawerContent = () => (
        <Box
            sx={{width: 250, backgroundColor: 'background.paper', height: '100%'}}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {menuItems.map((item) => (
                    <ListItem
                        button
                        key={item.text}
                        component={Link}
                        to={item.path}
                        selected={location.pathname === item.path}
                        sx={{
                            backgroundColor: location.pathname === item.path ? 'action.selected' : 'inherit',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text}/>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <AppBar
            position="sticky"
            sx={{
                backgroundColor: 'primary.dark',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            }}
        >
            <Toolbar>
                {isMobile && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                        sx={{mr: 2}}
                    >
                        <MenuIcon/>
                    </IconButton>
                )}
                <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'center'}}>
                    <Typography
                        variant="h5"
                        component={Link}
                        to="/"
                        sx={{
                            fontWeight: 'bold',
                            letterSpacing: '0.5px',
                            marginRight: 2,
                            textDecoration: 'none',
                            '&:hover': {
                                opacity: 0.8,
                            },
                        }}
                    >
                        <span style={{ color: '#FF0000' }}>Lal</span>{' '}
                        <span style={{ color: '#00FF00' }}>Shobuj</span>
                    </Typography>
                    <Typography
                        variant="subtitle1"
                        sx={{
                            color: 'common.white',
                            opacity: 0.9,
                            display: {xs: 'none', sm: 'inline'},
                            fontStyle: 'italic',
                            textShadow: '1px 1px 2px rgba(0,0,0,0.3)',
                        }}
                    >
                        Birodh • Obhijog • Mukti • Protirodh
                    </Typography>
                </Box>
                {!isMobile ? (
                    <nav>
                        <Box sx={{display: 'flex'}}>
                            {menuItems.map((item) => (
                                <Tooltip title={item.text} key={item.text}>
                                    <Button
                                        component={Link}
                                        to={item.path}
                                        sx={{
                                            color: 'common.white',
                                            textTransform: 'none',
                                            mx: 1,
                                            fontWeight: location.pathname === item.path ? 'bold' : 'normal',
                                            borderBottom: location.pathname === item.path ? '2px solid white' : 'none',
                                            borderRadius: '4px 4px 0 0',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255,255,255,0.1)',
                                            },
                                        }}
                                    >
                                        {item.text}
                                    </Button>
                                </Tooltip>
                            ))}
                        </Box>
                    </nav>
                ) : (
                    <Typography variant="subtitle1" sx={{color: 'common.white'}}>
                        {menuItems.find(item => item.path === location.pathname)?.text || 'Home'}
                    </Typography>
                )}
                <ThemeSwitcher/>
            </Toolbar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <DrawerContent/>
            </Drawer>
        </AppBar>
    );
};

export default React.memo(Header);

*/


/*
import React, {useState, useCallback} from 'react';
import {
    AppBar,
    Toolbar,
    Typography,
    Box,
    Drawer,
    List,
    ListItem,
    ListItemText,
    ListItemIcon,
    IconButton,
    useTheme,
    useMediaQuery,
    Tooltip,
    Button
} from '@mui/material';
import {Link, useLocation} from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import MapIcon from '@mui/icons-material/Map';
import NewsIcon from '@mui/icons-material/Announcement';
import MemorialIcon from '@mui/icons-material/EmojiPeople';
import ThemeSwitcher from '../ThemeSwitcher';
import FlagIcon from '@mui/icons-material/Flag';

const menuItems = [
    {text: 'Home', icon: <MapIcon/>, path: '/'},
    {text: 'News Feed', icon: <NewsIcon/>, path: '/news'},
    {text: 'Memorial', icon: <MemorialIcon/>, path: '/memorial'},
];

const Header: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();

    const toggleDrawer = useCallback((open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    }, []);

    const DrawerContent = () => (
        <Box
            sx={{width: 250, backgroundColor: 'background.paper', height: '100%'}}
            role="presentation"
            onClick={toggleDrawer(false)}
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {menuItems.map((item) => (
                    <ListItem
                        button
                        key={item.text}
                        component={Link}
                        to={item.path}
                        selected={location.pathname === item.path}
                        sx={{
                            backgroundColor: location.pathname === item.path ? 'action.selected' : 'inherit',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text}/>
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <AppBar
            position="sticky"
            sx={{
                backgroundColor: 'primary.dark',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            }}
        >
            <Toolbar>
                {isMobile && (
                    <IconButton
                        edge="start"
                        color="inherit"
                        aria-label="menu"
                        onClick={toggleDrawer(true)}
                        sx={{mr: 2}}
                    >
                        <MenuIcon/>
                    </IconButton>
                )}
                <Box sx={{flexGrow: 1, display: 'flex', alignItems: 'baseline'}}>
                    <Typography
                        variant="h6"
                        component="h1"
                        sx={{
                            fontWeight: 'bold',
                            letterSpacing: '0.5px',
                            marginRight: 2,
                        }}
                    >
                        <span style={{ color: '#FF0000' }}>Lal</span>{' '}
                        <span style={{ color: '#00FF00' }}>Shobuj</span>
                    </Typography>
                    <Typography
                        variant="subtitle2"
                        sx={{
                            color: 'common.white',
                            opacity: 0.7,
                            display: {xs: 'none', sm: 'inline'},
                        }}
                    >
                        Birodh, Obhijog, Mukti, Protirodh
                    </Typography>
                </Box>
                {/!*<Box sx={{flexGrow: 1, display: 'flex', alignItems: 'baseline'}}>*!/}
                {/!*    <Typography*!/}
                {/!*        variant="h6"*!/}
                {/!*        component="h1"*!/}
                {/!*        sx={{*!/}
                {/!*            color: 'common.white',*!/}
                {/!*            fontWeight: 'bold',*!/}
                {/!*            letterSpacing: '0.5px',*!/}
                {/!*            marginRight: 2,*!/}
                {/!*        }}*!/}
                {/!*    >*!/}
                {/!*        Lal Shobuj*!/}
                {/!*    </Typography>*!/}
                {/!*    <Typography*!/}
                {/!*        variant="subtitle2"*!/}
                {/!*        sx={{*!/}
                {/!*            color: 'common.white',*!/}
                {/!*            opacity: 0.7,*!/}
                {/!*            display: {xs: 'none', sm: 'inline'},*!/}
                {/!*        }}*!/}
                {/!*    >*!/}
                {/!*        Birodh, Obhijog, Mukti, Protirodh*!/}
                {/!*    </Typography>*!/}
                {/!*</Box>*!/}
                {!isMobile ? (
                    <nav>
                        <Box sx={{display: 'flex'}}>
                            {menuItems.map((item) => (
                                <Tooltip title={item.text} key={item.text}>
                                    <Button
                                        component={Link}
                                        to={item.path}
                                        sx={{
                                            color: 'common.white',
                                            textTransform: 'none',
                                            mx: 1,
                                            fontWeight: location.pathname === item.path ? 'bold' : 'normal',
                                            borderBottom: location.pathname === item.path ? '2px solid white' : 'none',
                                            borderRadius: '4px 4px 0 0',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255,255,255,0.1)',
                                            },
                                        }}
                                    >
                                        {item.text}
                                    </Button>
                                </Tooltip>
                            ))}
                        </Box>
                    </nav>
                ) : (
                    <Typography variant="subtitle1" sx={{color: 'common.white'}}>
                        {menuItems.find(item => item.path === location.pathname)?.text || 'Home'}
                    </Typography>
                )}
                <ThemeSwitcher/>
            </Toolbar>
            <Drawer
                anchor="left"
                open={drawerOpen}
                onClose={toggleDrawer(false)}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <DrawerContent/>
            </Drawer>
        </AppBar>
    );
};

export default React.memo(Header);
*/

/*
import React, { useState, useCallback } from 'react';
import { AppBar, Toolbar, Typography, Box, Drawer, List, ListItem, ListItemText, ListItemIcon, IconButton, useTheme, useMediaQuery, Tooltip, Button } from '@mui/material';
import { Link, useLocation } from 'react-router-dom';
import MenuIcon from '@mui/icons-material/Menu';
import MapIcon from '@mui/icons-material/Map';
import NewsIcon from '@mui/icons-material/Announcement';
import MemorialIcon from '@mui/icons-material/EmojiPeople';
import ThemeSwitcher from '../ThemeSwitcher';

const menuItems = [
    { text: 'Home', icon: <MapIcon />, path: '/' },
    { text: 'News Feed', icon: <NewsIcon />, path: '/news' },
    { text: 'Memorial', icon: <MemorialIcon />, path: '/memorial' },
];

const Header: React.FC = () => {
    const [drawerOpen, setDrawerOpen] = useState(false);
    const theme = useTheme();
    const isMobile = useMediaQuery(theme.breakpoints.down('sm'));
    const location = useLocation();

    const toggleDrawer = useCallback((open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }
        setDrawerOpen(open);
    }, []);

    const DrawerContent = () => (
        <Box 
            sx={{ width: 250, backgroundColor: 'background.paper', height: '100%' }} 
            role="presentation" 
            onClick={toggleDrawer(false)} 
            onKeyDown={toggleDrawer(false)}
        >
            <List>
                {menuItems.map((item) => (
                    <ListItem 
                        button 
                        key={item.text} 
                        component={Link} 
                        to={item.path} 
                        selected={location.pathname === item.path}
                        sx={{
                            backgroundColor: location.pathname === item.path ? 'action.selected' : 'inherit',
                            '&:hover': {
                                backgroundColor: 'action.hover',
                            },
                        }}
                    >
                        <ListItemIcon>{item.icon}</ListItemIcon>
                        <ListItemText primary={item.text} />
                    </ListItem>
                ))}
            </List>
        </Box>
    );

    return (
        <AppBar
            position="sticky"
            sx={{
                backgroundColor: 'primary.dark',
                boxShadow: '0 2px 4px rgba(0,0,0,0.2)',
            }}
        >
            <Toolbar>
                {isMobile && (
                    <IconButton 
                        edge="start" 
                        color="inherit" 
                        aria-label="menu" 
                        onClick={toggleDrawer(true)}
                        sx={{ mr: 2 }}
                    >
                        <MenuIcon />
                    </IconButton>
                )}
                <Typography
                    variant="h6"
                    component="h1"
                    sx={{
                        flexGrow: 1,
                        color: 'common.white',
                        fontWeight: 'bold',
                        letterSpacing: '0.5px',
                    }}
                >
                    Shahid Smriti
                </Typography>
                {!isMobile ? (
                    <nav>
                        <Box sx={{ display: 'flex' }}>
                            {menuItems.map((item) => (
                                <Tooltip title={item.text} key={item.text}>
                                    <Button
                                        component={Link}
                                        to={item.path}
                                        sx={{
                                            color: 'common.white',
                                            textTransform: 'none',
                                            mx: 1,
                                            fontWeight: location.pathname === item.path ? 'bold' : 'normal',
                                            borderBottom: location.pathname === item.path ? '2px solid white' : 'none',
                                            borderRadius: '4px 4px 0 0',
                                            '&:hover': {
                                                backgroundColor: 'rgba(255,255,255,0.1)',
                                            },
                                        }}
                                    >
                                        {item.text}
                                    </Button>
                                </Tooltip>
                            ))}
                        </Box>
                    </nav>
                ) : (
                    <Typography variant="subtitle1" sx={{ color: 'common.white' }}>
                        {menuItems.find(item => item.path === location.pathname)?.text || 'Home'}
                    </Typography>
                )}
                <ThemeSwitcher />
            </Toolbar>
            <Drawer 
                anchor="left" 
                open={drawerOpen} 
                onClose={toggleDrawer(false)}
                ModalProps={{
                    keepMounted: true,
                }}
            >
                <DrawerContent />
            </Drawer>
        </AppBar>
    );
};

export default React.memo(Header);*/
