import { Incident } from "../types/Incident";


const jsonData: ({
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: null;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
} | {
    date: string;
    profession: string;
    education: string;
    perpetrator: string;
    latitude: number;
    verified: boolean;
    description: string;
    cause: string;
    source: string;
    title: string;
    additionalDetails: string;
    verifiedDate: null;
    movementType: string;
    name: string;
    links: any[];
    id: string;
    age: number;
    longitude: number;
    status: string
})[] = [
        /*{
            "id": "1",
            "name": "Abdul Ahad",
            "age": 4,
            "title": "Child Killed in Clashes",
            "description": "Abdul Ahad was killed after being hit by a bullet in his eye while standing on the verandah of his house.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "bullet injury",
            "date": "2024-07-19",
            "additionalDetails": "Abdul Ahad was killed by a bullet while standing on his verandah.",
            "education": "none",
            "profession": "none",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "2",
            "name": "Riya Gope",
            "age": 6,
            "title": "Child Killed in Clashes",
            "description": "Riya Gope was killed after being hit by a bullet in her head while playing on the rooftop of her house.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "bullet injury",
            "date": "2024-07-19",
            "additionalDetails": "Riya Gope was killed by a bullet while playing on her rooftop.",
            "education": "primary school",
            "profession": "none",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "3",
            "name": "Hossain Mia",
            "age": 10,
            "title": "Child Killed in Clashes",
            "description": "Hossain Mia was killed after sustaining two bullet injuries in his body.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "bullet injury",
            "date": "2024-07-19",
            "additionalDetails": "Hossain Mia sustained two bullet injuries in his body.",
            "education": "primary school",
            "profession": "none",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "4",
            "name": "Abu Saeed",
            "age": null,
            "title": "University Student Killed in Clashes",
            "description": "Abu Saeed, a university student, was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-19",
            "additionalDetails": "Abu Saeed was a student of Begum Rokeya University and was killed in clashes.",
            "education": "university",
            "profession": "student",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "5",
            "name": "Omar Faruk",
            "age": 17,
            "title": "Student Killed in Clashes",
            "description": "Omar Faruk, a seventeen-year-old student, was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-19",
            "additionalDetails": "Omar Faruk was a student of Dhaka Residential Model College and was killed in clashes.",
            "education": "high school",
            "profession": "student",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "6",
            "name": "Jasim Uddin",
            "age": 35,
            "title": "Man Killed in Clashes",
            "description": "Jasim Uddin was killed after sustaining a bullet injury in his chest.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "bullet injury",
            "date": "2024-07-19",
            "additionalDetails": "Jasim Uddin was a manager of a vehicle shop and was killed while going outside to purchase spare parts.",
            "education": "unknown",
            "profession": "manager",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "7",
            "name": "Saad Mahmud Khan",
            "age": 14,
            "title": "Child Killed in Clashes",
            "description": "Saad Mahmud Khan was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-17",
            "additionalDetails": "Saad Mahmud Khan was killed in clashes.",
            "education": "unknown",
            "profession": "unknown",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "8",
            "name": "Tahmid Bhuiyan",
            "age": 15,
            "title": "Child Killed in Clashes",
            "description": "Tahmid Bhuiyan was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-19",
            "additionalDetails": "Tahmid Bhuiyan was a student of Tangail and was killed in clashes.",
            "education": "high school",
            "profession": "student",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "9",
            "name": "Imran",
            "age": 16,
            "title": "Child Killed in Clashes",
            "description": "Imran was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-19",
            "additionalDetails": "Imran was a resident of Narayanganj and was killed in clashes.",
            "education": "high school",
            "profession": "none",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "10",
            "name": "Sujan Mia",
            "age": 17,
            "title": "Child Killed in Clashes",
            "description": "Sujan Mia was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-19",
            "additionalDetails": "Sujan Mia was a resident of Narsingdi and was killed in clashes.",
            "education": "high school",
            "profession": "none",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "11",
            "name": "Hridoy Chandra Tarua",
            "age": 22,
            "title": "University Student Killed in Clashes",
            "description": "Hridoy Chandra Tarua was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-19",
            "additionalDetails": "Hridoy Chandra Tarua was a student of Begum Rokeya University and was killed in clashes.",
            "education": "university",
            "profession": "student",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "12",
            "name": "Rafiq Ahmed",
            "age": 18,
            "title": "Student Killed in Clashes",
            "description": "Rafiq Ahmed was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-19",
            "additionalDetails": "Rafiq Ahmed was a student and was killed in clashes.",
            "education": "high school",
            "profession": "student",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "13",
            "name": "Rahul Islam",
            "age": 25,
            "title": "University Student Killed in Clashes",
            "description": "Rahul Islam was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-19",
            "additionalDetails": "Rahul Islam was a student of Dhaka University and was killed in clashes.",
            "education": "university",
            "profession": "student",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "14",
            "name": "Hasan Ahmed",
            "age": 20,
            "title": "University Student Killed in Clashes",
            "description": "Hasan Ahmed was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-19",
            "additionalDetails": "Hasan Ahmed was a student of Jahangirnagar University and was killed in clashes.",
            "education": "university",
            "profession": "student",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "15",
            "name": "Mohiuddin Ahmed",
            "age": 19,
            "title": "University Student Killed in Clashes",
            "description": "Mohiuddin Ahmed was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-19",
            "additionalDetails": "Mohiuddin Ahmed was a student of Chittagong University and was killed in clashes.",
            "education": "university",
            "profession": "student",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "16",
            "name": "Shakil Ahmed",
            "age": 21,
            "title": "University Student Killed in Clashes",
            "description": "Shakil Ahmed was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-19",
            "additionalDetails": "Shakil Ahmed was a student of Khulna University and was killed in clashes.",
            "education": "university",
            "profession": "student",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "17",
            "name": "Jahangir Alam",
            "age": 26,
            "title": "University Student Killed in Clashes",
            "description": "Jahangir Alam was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-19",
            "additionalDetails": "Jahangir Alam was a student of Rajshahi University and was killed in clashes.",
            "education": "university",
            "profession": "student",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "18",
            "name": "Nasir Uddin",
            "age": 27,
            "title": "University Student Killed in Clashes",
            "description": "Nasir Uddin was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-19",
            "additionalDetails": "Nasir Uddin was a student of North South University and was killed in clashes.",
            "education": "university",
            "profession": "student",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        },
        {
            "id": "19",
            "name": "Rafiul Islam",
            "age": 18,
            "title": "Student Killed in Clashes",
            "description": "Rafiul Islam was killed in clashes.",
            "latitude": 23.7104,
            "longitude": 90.3977,
            "status": "killed",
            "perpetrator": "unknown",
            "cause": "unknown",
            "date": "2024-07-19",
            "additionalDetails": "Rafiul Islam was a student and was killed in clashes.",
            "education": "high school",
            "profession": "student",
            "verified": true,
            "verifiedDate": null,
            "movementType": "Quota Reform Movement",
            "source": "prothom-alo.com",
            "links": []
        }*/
    ];


export const incidents: Incident[] = jsonData.map(item => ({
    id: item.id,
    name: item.name,
    age: item.age,
    title: item.title,
    description: item.description,
    latitude: item.latitude,
    longitude: item.longitude,
    status: item.status as "killed" | "injured" | "missing" | "detained" | "harassed" | "threatened" | "recovered",
    perpetrator: item.perpetrator,
    cause: item.cause,
    date: item.date,
    additionalDetails: item.additionalDetails,
    education: item.education,
    profession: item.profession,
    verified: item.verified,
    verifiedDate: item.verifiedDate,
    movementType: item.movementType,
    source: item.source,
    links: item.links as []
}));
